@import 'variables';

.medis-table {
  overflow-x: auto;

  &.simple-header {
    .mat-mdc-header-row .mat-mdc-header-cell,
    .mat-mdc-header-row .mat-mdc-header-cell > .mat-sort-header,
    .mat-mdc-header-row .mat-mdc-header-cell > .mat-sort-header-container {
      font-size: 13px;
      font-weight: 900;
      color: $color-mostly-black;
      text-transform: uppercase;
    }
  }

  .mat-mdc-header-row .mat-mdc-header-cell {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 5px;

    > .mat-sort-header,
    > .mat-sort-header-container,
    > .header-text,
    &.header-text {
      font-size: 10px;
      font-weight: 800;
      color: $color-light-grey;
      text-transform: uppercase;

      .mat-sort-header-content {
        max-width: calc(100% - 18px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .mat-mdc-row .mat-mdc-cell {
    padding: 0 5px;
    font-size: 14px;
    font-weight: 500;
    color: $color-charcoal-grey;
  }

  .mat-mdc-row .mat-mdc-cell:first-of-type,
  .mat-mdc-header-row .mat-mdc-header-cell:first-of-type {
    padding-left: 15px;
  }

  .mat-mdc-row .mat-mdc-cell:last-of-type,
  .mat-mdc-header-row .mat-mdc-header-cell:last-of-type {
    padding-right: 15px;
  }

  @mixin mat-cell($minWidth, $grow, $alias: '') {
    .mat-cell-#{$minWidth}#{$alias} {
      flex: #{$grow} 0 #{$minWidth}px;
    }
  }

  @include mat-cell(40, 0, '-fixed');
  @include mat-cell(60, 0, '-fixed');
  @include mat-cell(70, 0, '-fixed');
  @include mat-cell(80, 0, '-fixed');
  @include mat-cell(100, 0, '-fixed');
  @include mat-cell(60, 1);
  @include mat-cell(100, 1);
  @include mat-cell(200, 2);
}

mat-table {
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0;
  }

  .mat-mdc-row {
    border-bottom: none;

    &.highlighted .mat-mdc-cell {
      background: rgba(24, 171, 255, 0.3);
    }

    &:nth-child(odd) {
      background-color: $color-white;
    }

    &:nth-child(even) {
      background-color: $color-pale-grey;
    }

    &:hover {
      background-color: $color-ice-blue;
    }

    > .mat-mdc-cell {
      border-bottom-style: hidden;
    }
  }
}

mat-table {
  --mat-table-header-headline-color: #{$color-light-grey};
  --mat-table-header-headline-line-height: 15px;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-tracking: normal;
}
