@import 'variables';

@mixin border-width($width) {
  .mdc-notched-outline__leading {
    border-width: $width 0px $width $width !important;
  }

  .mdc-notched-outline__notch {
    border-width: $width 0px !important;
  }

  .mdc-notched-outline__trailing {
    border-width: $width $width $width 0px !important;
  }
}

@mixin border-color($color) {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $color !important;
  }
}

@mixin color-variant($name, $label-color, $bg-color, $border-color: $label-color) {
  &.#{$name} {
    mat-label {
      color: $label-color;
    }

    .mat-mdc-text-field-wrapper {
      background-color: $bg-color;
    }

    @include border-color($border-color);
    @content;
  }
}

mat-form-field.form-field-wrapper {
  margin: 5px 0;

  &.mat-form-field-disabled {
    cursor: not-allowed;

    .mat-mdc-floating-label,
    input:disabled {
      cursor: not-allowed;
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: center;
  }

  mat-select,
  input {
    padding: 5px 12px;
  }

  &.mat-mdc-form-field-type-mat-select .mdc-notched-outline__notch {
    max-width: calc(100% - 35px);
  }

  .mat-mdc-form-field-subscript-dynamic-size {
    position: relative;

    > .mat-mdc-form-field-error-wrapper {
      position: absolute;
      top: -3px;
    }
  }

  &.subscript-block
    > .mat-mdc-form-field-subscript-dynamic-size
    > .mat-mdc-form-field-error-wrapper {
    position: relative;
  }

  mat-error.mat-mdc-form-field-error {
    font-size: 11px;
    text-align: left;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  &.no-border {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-style: none;
    }
  }

  @include color-variant('invalid', $color-coral, $color-pale-coral);
  @include color-variant('ng-invalid.ng-touched', $color-coral, $color-pale-coral);
  @include color-variant('suggestion', $color-orange-yellow, $color-pale-yellow);
  @include color-variant('toolbar', $color-white, $color-azure, $color-cerulean) {
    @include border-width(2px);

    mat-label {
      font-size: 13px;
      font-weight: 600;
    }
  }
  @include color-variant('rounded-white', $color-light-grey, $color-white, $color-light-sky-blue) {
    .mat-mdc-text-field-wrapper {
      border-radius: 25px;
    }

    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-radius: 25px 0px 0px 25px;
        min-width: 25px;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0px 25px 25px 0px;
        min-width: 25px;
      }
    }

    input {
      padding-left: 0;
    }

    label {
      left: 0;
    }
  }

  &.fs-10,
  &.inline-edit {
    mat-label,
    mat-select {
      font-size: 10px;
    }

    mat-select,
    input {
      padding: 5px;
      line-height: 13.5px;
    }
  }

  &.fs-10 {
    margin-top: 0;
  }

  &.inline-edit {
    .mat-mdc-floating-label {
      top: 14px;
    }

    &:not(.invalid) {
      @include border-width(2px);
      @include border-color($color-azure);
    }
  }

  &.show-placeholder {
    &.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
      color: $color-light-grey;
      -webkit-text-fill-color: $color-light-grey;
    }
  }
}

mat-form-field {
  &.mat-mdc-paginator-page-size-select {
    --mat-form-field-container-height: 25px;
  }

  --mat-form-field-container-height: 34px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-vertical-padding: 0;
  --mat-form-field-outlined-label-text-populated-size: 13px;
  --mat-form-field-state-layer-color: transparent;
  --mat-form-field-subscript-text-size: 11px;
  --mat-form-field-subscript-text-line-height: 11px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 700;
  --mat-select-placeholder-text-color: #{$color-light-grey};
  --mat-select-trigger-text-size: 13px;
  --mat-select-trigger-text-weight: 500;
  --mdc-outlined-text-field-disabled-label-text-color: #{$color-light-grey};
  --mdc-outlined-text-field-disabled-outline-color: #{$color-light-sky-blue};
  --mdc-outlined-text-field-focus-label-text-color: #{$color-light-grey};
  --mdc-outlined-text-field-focus-outline-color: #{$color-azure};
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-hover-label-text-color: #{$color-light-grey};
  --mdc-outlined-text-field-hover-outline-color: #{$color-light-sky-blue};
  --mdc-outlined-text-field-label-text-color: #{$color-light-grey};
  --mdc-outlined-text-field-label-text-size: 13px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-outline-color: #{$color-light-sky-blue};
}
