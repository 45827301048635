@import 'variables';

@mixin state-styles($state, $component, $color) {
  $sub-states: ('', '-hover', '-focus', '-pressed');
  @each $sub-state in $sub-states {
    --mdc-switch-#{$state}#{$sub-state}-#{$component}-color: #{$color};
  }
}

html mat-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  @include state-styles('selected', 'icon', $color-azure);
  @include state-styles('selected', 'handle', $color-azure);
  @include state-styles('selected', 'track', $color-azure);
  @include state-styles('unselected', 'icon', #fafafa);
  @include state-styles('unselected', 'handle', #fafafa);
  @include state-styles('unselected', 'track', rgba(0, 0, 0, 0.38));
  --mdc-form-field-label-text-tracking: normal;
}
