@import 'variables';

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mat-mdc-select-panel {
  border-radius: 4px !important;
  border: 1px solid $color-azure;
  max-height: 350px !important;
  padding: 0 !important;

  mat-option.mat-mdc-option {
    min-height: unset;

    > .mdc-list-item__primary-text {
      width: 100%;
    }

    &.mdc-list-item--selected {
      background-color: $color-azure;
    }

    --mat-option-label-text-color: #{$color-light-grey};
    --mat-option-label-text-font: Manrope, sans-serif;
    --mat-option-label-text-line-height: 30px;
    --mat-option-label-text-size: 13px;
    --mat-option-label-text-tracking: normal;
    --mat-option-label-text-weight: 600;
    --mat-option-selected-state-layer-color: #{$color-azure};
  }
}
