@import 'variables';

mat-snack-bar-container.mat-mdc-snack-bar-container {
  &.red-snackbar {
    > .mdc-snackbar__surface {
      background: $color-coral;
      color: $color-white;

      .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: $color-pale-yellow;
      }
    }
  }
}
