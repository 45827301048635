@import 'variables.less';
@import 'material-icons.less';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 14px;
}

/* Global style to override tree component rules */
/* put a folder icon near to the non-leaf tree elements */
.folder > tree-node-wrapper > .node-wrapper > .node-content-wrapper:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f07b';
  color: #025aa5;
}

.document .node-content-wrapper {
  width: 100%;
}

.spinner-modal .modal-content {
  width: 0;
  border: 0;
  margin-left: 25%;
  margin-top: 20%;
}

.loading-container {
  z-index: 1060;
}

/* Suggestions style */
.mat-select.suggestion,
input.suggestion {
  background-color: @color-suggestions-future;
}

.mat-full-width {
  width: 100%;
}

/* fix clash between bootstrap modal and material select component */
.cdk-overlay-container {
  z-index: @z-index-select;
}

.mat-form-field-underline.mat-disabled {
  background-color: rgba(0, 0, 0, 0.42) !important;
  background-image: none;
}

.mat-raised-button-primary {
  background-color: @main-color;
  color: white !important;
}

.mat-raised-button-primary[disabled] {
  background-color: @not-active-color;
  color: white !important;
}

.mat-raised-button.mat-primary {
  background-color: @main-color;
  color: white !important;
}

.buttons-container {
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
}

.success-snack-bottom {
  margin-bottom: 25% !important;
  text-align: right;
}

.error-snack-bottom {
  margin-bottom: 25% !important;
  text-align: right;
  background-color: @color-errors;
}

.split-selected-text {
  font-weight: bold;
  color: @main-color;
  background-color: @highlight-background;
}

mat-expansion-panel-header.inquiries-list,
mat-expansion-panel-header.followup-list {
  background-color: @main-light-color !important;
  font-family: Manrope, sans-serif;

  span {
    color: @font-primary-color !important;
    font-family: Manrope, sans-serif;
  }
}

mat-expansion-panel-header.bounce-list,
mat-expansion-panel-header.error-list {
  background-color: @color-errors !important;
  font-family: Manrope, sans-serif;

  span {
    font-family: Manrope, sans-serif;
  }
}

.closed mat-expansion-panel-header.inquiries-list {
  background-color: @main-color-closed !important;
  font-family: Manrope, sans-serif;

  span {
    color: white !important;
  }
}

mat-expansion-panel-header.followup-list span {
  color: @inquirer-color !important;
  font-family: Manrope, sans-serif;
}

.editor-editable p,
.editor-editable ul {
  margin-bottom: 0;
  font-size: 11pt;
  font-family: Manrope, sans-serif;
}

#related-slide-carousel {
  img {
    height: 350px;
  }
}

.suggest-list {
  background: white;
  position: absolute;
  z-index: 999;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  width: 550px;
  max-width: 100%;
  height: 300px;
  min-height: 300px;
  max-height: 100%;
  overflow: auto;
}

.mat-placeholder-required,
.hint-required-container {
  color: #f44336;
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve"><g><g id="chevron-right"><polygon points="4.1,8 5,7 2,4 5,1 4.1,0 0.1,4 "/></g></g></svg>');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve"><g><g id="chevron-right"><polygon points="4,0 3.1,1 6.1,4 3.1,7 4,8 8,4 		"/></g></g></svg>');
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: @accent-color !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: @accent-light-color !important;
}

.mat-button-toggle-checked {
  background-color: @main-color;
  color: white;
}

.aligned-right {
  text-align: right;
}

.centered {
  text-align: center;
}

.row-save {
  padding-top: 15px;
}

.fa-1_5 {
  font-size: 1.5em;
}

.mat-step-header .mat-step-icon {
  background-color: @main-color;
}

.cdk-overlay-transparent-backdrop {
  background: rgba(0, 0, 0, 0);
}

td.mat-cell:not(:first-of-type) {
  padding: 0 1px;
}

// generic style for popup

@media (min-height: 400px) {
  .tiny-mce-popup iframe {
    height: 150px !important;
  }
}

@media (min-height: 500px) {
  .tiny-mce-popup iframe {
    height: 150px !important;
  }
}

@media (min-height: 600px) {
  .tiny-mce-popup iframe {
    height: 200px !important;
  }
}

@media (min-height: 700px) {
  .tiny-mce-popup iframe {
    height: 300px !important;
  }
}

@media (min-height: 800px) {
  .tiny-mce-popup iframe {
    height: 400px !important;
  }
}

@media (min-height: 900px) {
  .tiny-mce-popup iframe {
    height: 400px !important;
  }
}

.mce-edit-focus {
  outline: 2px solid @main-color !important;
}

.vertical-middle {
  display: flex;
  align-items: center;
}

.preview-icon {
  color: @font-primary-color;
  cursor: pointer;
}

app-detail-navigation,
app-history-visualization {
  .expansion-inquiry .mat-expansion-panel-header,
  .followup-list,
  .bounce-list,
  .error-list {
    padding: 0 15px 0 0;
  }

  .mat-expansion-panel-header-description {
    display: contents;
  }

  .expansion-inquiry .mat-expansion-panel-body,
  app-history-visualization .mat-expansion-panel-body {
    padding: 0;
  }

  .sidenav-container {
    width: 30%;
    background-color: @mockup-background;
  }

  .sidenav-container.over-mode {
    width: 60%;
  }

  .collapse-arrow-container {
    background-color: lightgray;
  }

  .expansion-inquiry {
    border-style: solid;
    border-color: @mockup-background;
    border-width: 2px;
    border-radius: 2px;
  }
}

.attachment-container {
  width: fit-content;
  display: inline-block;
}

.navigation-mobile-menu button:not(*[disabled]) {
  color: @font-primary-color !important;
}

.navigation-mobile-menu button {
  span {
    font-size: 14px;
  }
}

.mat-expansion-panel.selected,
.selectable.selected {
  border-style: solid;
  border-color: @main-color;
  border-width: 2px;
  border-radius: 2px;
}

.selectable.selected.suggestion {
  border-color: @color-suggestions-future;
}

.contact-row.suggestion {
  background-color: @color-suggestions-future !important;
}

.mat-content {
  align-items: center;
}

.g-0 {
  // http://getbootstrap.com/docs/4.1/layout/grid/#no-gutters
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.col-auto {
  padding-right: 15px;
  padding-left: 15px;
}

.empty p {
  line-height: 100px;
}

.icon-link-container {
  display: flex;
  align-items: center;
  color: @font-primary-color;
  cursor: pointer;
}

#time-picker-wrapper {
  z-index: 2000 !important;
}

.flexbox {
  flex: 1 1 1%;
}

input[type='file'] {
  display: none;
}

.spaced {
  padding-top: 15px;
}

.side-list {
  ul {
    list-style: none;
    padding: 0;
    margin-left: 15px;
  }

  .title-list {
    padding-top: 20px;
  }
}

.side-list.user {
  background-color: @main-light-color;
}

.side-list.metadata {
  background-color: lightgray;

  li.selected {
    background-color: #e1e1e1;
  }
}

.side-list.team {
  background-color: @team-base;

  li.selected {
    background-color: @team-base-light;
  }
}

.mat-paginator.team {
  background-color: @team-base-light;
}

/*design coming from bootstrap*/
button:focus {
  outline: unset !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #18abff !important;
}

.input-group-addon {
  background-color: transparent !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #18abff !important;
}

html,
body {
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
