@import 'variables';

mat-checkbox.mat-mdc-checkbox > .mdc-form-field {
  .mat-mdc-checkbox-touch-target {
    height: 16px;
    width: 16px;
  }

  label.mdc-label {
    text-wrap: nowrap;
  }

  --mdc-form-field-label-text-size: 13px;
  --mdc-form-field-label-text-weight: 700;
  --mdc-form-field-label-text-color: #{$color-gunmetal};
  --mdc-checkbox-unselected-icon-color: #{$color-gunmetal};
  --mdc-checkbox-unselected-hover-icon-color: #{$color-gunmetal};
  --mdc-checkbox-unselected-focus-icon-color: #{$color-gunmetal};
  --mdc-checkbox-selected-icon-color: #{$color-azure};
  --mdc-checkbox-selected-hover-icon-color: #{$color-azure};
  --mdc-checkbox-selected-focus-icon-color: #{$color-azure};
  --mdc-checkbox-selected-pressed-icon-color: #{$color-azure};
  --mdc-checkbox-state-layer-size: 16px;
}
