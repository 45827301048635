@import 'variables';

mat-chip-option.mat-mdc-standard-chip {
  &.advanced-search {
    min-width: 76px;
    height: 30px;
    padding: 6px 0 6px 15px;
    border-radius: 22.5px;
    width: 100%;

    &.centered {
      justify-content: center;

      .mdc-evolution-chip__cell--primary {
        display: none;
      }

      .mdc-evolution-chip__cell--trailing {
        flex: unset;
      }
    }

    .mat-mdc-chip-graphic {
      display: none;
    }
  }

  .mat-mdc-chip-remove {
    opacity: 1;
  }

  --mdc-chip-label-text-font: Manrope, sans-serif;
  --mdc-chip-label-text-line-height: 15.75px;
  --mdc-chip-label-text-tracking: normal;
}

mat-chip-listbox.chip-wrapper {
  display: inline-block;

  > .mdc-evolution-chip-set__chips {
    margin-left: 0;
  }

  &.invalid {
    mat-basic-chip {
      border-color: $color-coral;
      background-color: $color-pale-coral;
      color: $color-coral;
    }
  }

  &.suggestion {
    mat-basic-chip {
      border-color: $color-orange-yellow;
      background-color: $color-pale-yellow;
      color: $color-orange-yellow;

      &.chip-selected-basic {
        background-color: $color-orange-yellow;
        color: $color-pale-yellow;
      }
    }
  }

  &.contact-label {
    mat-chip-option {
      padding: 7px 0;

      .mat-mdc-chip-action-label {
        font-weight: 500;
      }
    }
  }

  mat-basic-chip {
    font-size: 11px;
    font-weight: 800;
    color: $color-cool-grey;
    border: 1px solid $color-cool-grey;
    padding: 3px 10px;
    cursor: pointer;

    .mat-mdc-chip-action-label {
      cursor: pointer;
    }
  }

  @mixin chip-selected($state-name, $color, $background-color) {
    .chip-selected-#{$state-name} {
      background-color: $background-color;
      border-color: $background-color;

      .mat-mdc-chip-action-label {
        color: $color;
      }
    }
  }

  @include chip-selected('urgent', #fff, $color-coral);
  @include chip-selected('high', #fff, $color-orange-yellow);
  @include chip-selected('normal', #fff, $color-grass);
  @include chip-selected('disabled', #fff, $color-lighter-grey);
  @include chip-selected('basic', #fff, $color-azure);

  .chip-first {
    border-bottom-left-radius: 11.5px;
    border-top-left-radius: 11.5px;
  }

  .chip-inner {
    border-left-style: none;
  }

  .chip-last {
    border-bottom-right-radius: 11.5px;
    border-top-right-radius: 11.5px;
    border-left-style: none;
  }
}
