@import 'variables';

mat-tab-group.mat-mdc-tab-group {
  .mdc-tab {
    text-transform: uppercase;
    min-width: 160px;

    &:not(.mdc-tab--active) {
      opacity: 0.6;
    }
  }

  .mat-mdc-tab-header {
    --mat-tab-header-label-text-tracking: normal;
    --mdc-secondary-navigation-tab-container-height: 62px;
  }

  --mat-tab-header-active-focus-indicator-color: #{$color-azure};
  --mat-tab-header-active-focus-label-text-color: #{$color-black};
  --mat-tab-header-active-hover-indicator-color: #{$color-azure};
  --mat-tab-header-active-hover-label-text-color: #{$color-black};
  --mat-tab-header-active-label-text-color: #{$color-black};
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-divider-color: #{$color-lighter-grey};
  --mat-tab-header-divider-height: 1px;
  --mat-tab-header-inactive-focus-label-text-color: #{$color-mostly-black};
  --mat-tab-header-inactive-hover-label-text-color: #{$color-mostly-black};
  --mat-tab-header-inactive-label-text-color: #{$color-mostly-black};
  --mat-tab-header-inactive-ripple-color: transparent;
  --mdc-tab-indicator-active-indicator-color: #{$color-azure};
  --mdc-tab-indicator-active-indicator-height: 4px;
}
