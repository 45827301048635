@import 'variables';

.mat-mdc-tooltip-panel {
  white-space: pre-wrap;

  &:has(.mat-mdc-tooltip.w80-h100) {
    pointer-events: none;
  }
}

.mat-mdc-tooltip {
  &.auto-width > .mdc-tooltip__surface {
    max-width: unset;
  }

  &.auto-width-height > .mdc-tooltip__surface {
    max-width: unset;
    max-height: unset;
  }

  &.w80-h100 > .mdc-tooltip__surface {
    max-width: 80%;
    max-height: 100%;
  }

  > .mdc-tooltip__surface {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $color-light-sky-blue;
    padding: 7px 9px;
    max-width: 250px;

    --mdc-plain-tooltip-container-color: #{$color-white};
    --mdc-plain-tooltip-container-shape: 6px;
    --mdc-plain-tooltip-supporting-text-color: #{$color-gunmetal};
    --mdc-plain-tooltip-supporting-text-size: 12px;
    --mdc-plain-tooltip-supporting-text-weight: 500;
  }
}
